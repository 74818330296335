<template>
  <div>
    <div class="vx-row">
      <feather-icon
        @click="back()"
        :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl">
        {{ $t("DoctorProfileData") }}
      </p>
    </div>

    <div class="ml-10 mr-10">
      <MainInfo :doctorModel="doctorModel" />

      <DoctorMoreInfo class="mt-5" :doctorModel="doctorModel" />

      <div class="w-full mt-5">
        <div class="justify-right">
          <vs-button :disabled="!isFormValid" @click="submitData()" type="filled" style="float: right">{{
            $t("Submit")
          }}</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MainInfo from "@/views/doctorProfile/MInInfoo.vue";
import DoctorMoreInfo from "@/views/doctorProfile/DoctorMoreInfo.vue";
import moduleCity from "@/store/settings/city/moduleCity.js";

import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleRegion from "@/store/settings/region/moduleRegion.js";

export default {
  components: {
    MainInfo,
    DoctorMoreInfo,
  },
  data() {
    return {
      phonevalue:'',
      EnglishLan: false,
      doctorModel: {
        specialities: [],
        DoctorSpecialities: [],
        Langauges: [],
        DoctorLanguages: [],
        PatientMedicalHistory: {},
        Next: false,
        CVPath: null,
        Doctorphonevalue:'',
        CurrentUSerCountryID:0
      },
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    getDoctorData() {
      this.$vs.loading();
      this.$store
        .dispatch(
          "DoctorList/GetDoctor",
          this.$store.state.AppActiveUser.Doctor.ID
        )
        .then((res) => {
          debugger;
          this.doctorModel = res.data.Data;
          this.phonevalue = res.data.Data.Phone;
          console.log(this.phonevalue);
          this.doctorModel.phonevalue = res.data.Data.Phone;
          this.doctorModel.CurrentUSerCountryID=res.data.Data.CountryID;
           this.$store
        .dispatch("CityList/GetAllCitiesByRegionID", this.doctorModel.RegionID)
           this.$store
        .dispatch(
          "RegionList/GetAllRegionsByCountryID",
          this.doctorModel.CountryID
        )
          this.doctorModel.specialities =
            this.doctorModel.DoctorSpecialities.map((a) => a.SpecialtyID);
          this.$store.commit(
            "DoctorList/SET_specialities",
            this.doctorModel.specialities
          );
          this.doctorModel.Langauges = this.doctorModel.DoctorLanguages.map(
            (a) => a.LanguageID
          );
          this.$store.commit(
            "DoctorList/SET_DoctorLanguages",
            this.doctorModel.Langauges
          );

          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          window.showError(err.response.data.message);
        });
    },
    submitData() {
      debugger;
    // this.doctorModel.Phone = this.doctorModel.phonevalue;
    // this.doctorModel.Phone = this.doctorModel.Doctorphonevalue;
      this.phonevalue=this.doctorModel.phone;
      this.doctorModel.DoctorSpecialities =
        this.$store.state.DoctorList.specialities.map(function (val) {
          var obj = {};
          obj.SpecialtyID = val;
          return obj;
        });
      this.doctorModel.DoctorLanguages =
        this.$store.state.DoctorList.Langauges.map(function (val) {
          var obj = {};
          obj.LanguageID = val;
          return obj;
        });
      //this.$vs.loading();
      this.doctorModel.CVPath;
      this.$store
        .dispatch("DoctorList/UpdateDoctor", this.doctorModel)
        .then((res) => {
          console.error(res);
          //this.$vs.loading.close();
          window.showSuccess(res.data.message);
          this.$router.push({ name: "doctorLandingPage" });
        })
        .catch((err) => {
          this.$vs.loading.close();
          window.showError(err.response.data.message);
          console.error(err);
        });
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
  },
  computed: {
    isFormValid() {
      return !this.errors.any() && this.doctorModel && this.doctorModel.CountryID&& this.doctorModel.CountryID!=null
       &&this.doctorModel.NameEN && this.doctorModel.FamilyName && this.doctorModel.GenderID
       && this.doctorModel.FullName && this.doctorModel.RegionID&& this.doctorModel.CityID && this.doctorModel.TitleEN ;
    },
    // pathCVObj() {
    //   return this.$store.state.DoctorList.pathCV;
    // },
    ListOfSpecialities() {
      return this.$store.state.SpecialtyList.specialties;
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;
    if (!moduleRegion.isRegistered) {
      this.$store.registerModule("RegionList", moduleRegion);
      moduleRegion.isRegistered = true;
    }

    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }

    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }


    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }

      this.$store.dispatch("CountryList/GetAllCountries");

    this.$store.dispatch("SpecialtyList/GetAllSpecialties");

    this.getDoctorData();
  },
};
</script>
<style>
.circle {
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
}
.textfont {
  font-family: "futuraMedium";
}
</style>
